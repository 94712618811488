<template>
    <div>
        <div class="row">
            <div class="col-12 mt-4 d-flex justify-content-between">
                <h6>1- {{ $t('personal_information') }}</h6>
                <b-link v-if="personalInfo.personalInfo.gno" @click="goTo(0)">Düzenle</b-link>
            </div>
            <div class="col-12">
                <hr/>
            </div>
            <div class="col-6 col-lg-4">
                <b-form-group :label="$t('national_id')">
                    {{ personalInfo.personalInfo.national_id }}
                </b-form-group>
            </div>
            <div class="col-6 col-lg-4">
                <b-form-group :label="$t('name')">
                    {{ personalInfo.personalInfo.name }}
                </b-form-group>
            </div>
            <div class="col-6 col-lg-4">
                <b-form-group :label="$t('surname')">
                    {{ personalInfo.personalInfo.surname }}
                </b-form-group>
            </div>
            <div class="col-6 col-lg-4">
                <b-form-group :label="$t('gender')">
                    {{ personalInfo.personalInfo.gender }}
                </b-form-group>
            </div>
            <div class="col-6 col-lg-4">
                <b-form-group :label="$t('date_of_birth')">
                    {{ personalInfo.personalInfo.birthdate }}
                </b-form-group>
            </div>
            <div class="col-6 col-lg-4">
                <b-form-group :label="$t('nationality')">
                    {{ personalInfo.personalInfo.nationality_code }}
                </b-form-group>
            </div>
            <div class="col-6 col-lg-4">
                <b-form-group :label="$t('military_status')">
                    {{ stateData.military_status_name }}
                </b-form-group>
            </div>
            <div class="col-6 col-lg-4">
                <b-form-group :label="$t('email')">
                    {{ personalInfo.personalInfo.email }}
                </b-form-group>
            </div>
            <div class="col-6 col-lg-4">
                <b-form-group :label="$t('mobile_number')">
                    {{ personalInfo.personalInfo.phone }}
                </b-form-group>
            </div>
            <div class="col-6 col-lg-4">
                <b-form-group :label="$t('application_type')">
                    {{ stateData.application_type_name }}
                </b-form-group>
            </div>
            <div class="col-12 mt-4 d-flex justify-content-between">
                <h6>2- {{ $t('programs_selected') }}</h6>
                <b-link @click="goTo(1)">Düzenle</b-link>
            </div>
            <div class="col-12">
                <hr/>
            </div>
            <div class="col-12">
                <b-table
                    :empty-text="this.$t('there_are_no_records')"
                    bordered
                    hover
                    striped
                    responsive
                    :items="selectedPrograms"
                    :fields="selectedProgramsFields"
                    show-empty
                    class="mb-5 table-dropdown no-scrollbar border rounded">
                </b-table>
            </div>
            <div class="col-12 mt-4 d-flex justify-content-between">
                <h6>3- {{ $t('job_and_contact_info') }}</h6>
                <b-link @click="goTo(2)">Düzenle</b-link>
            </div>
            <div class="col-12">
                <hr/>
            </div>
            <div class="col-12">
                <h6>{{ $t('job_info') }}</h6>
            </div>
            <div class="col-6 col-lg-4">
                <b-form-group :label="$t('job_sector')">
                    {{stateData != null ? stateData.sector_name : '-'}}
                </b-form-group>
            </div>
            <div class="col-6 col-lg-4">
                <b-form-group :label="$t('job_corporation')">
                    {{stateData != null ? stateData.institution_working : '-'}}
                </b-form-group>
            </div>
            <div class="col-6 col-lg-4">
                <b-form-group :label="$t('job')">
                    {{stateData != null ? stateData.job : '-'}}
                </b-form-group>
            </div>
            <div class="col-6 col-lg-4">
                <b-form-group :label="$t('job_department')">
                    {{stateData != null ? stateData.working_unit : '-'}}
                </b-form-group>
            </div>
            <div class="col-6 col-lg-4">
                <b-form-group :label="$t('title1')">
                    {{stateData != null ? stateData.title_name : '-'}}
                </b-form-group>
            </div>
            <div class="col-6 col-lg-4">
                <b-form-group :label="$t('job_experience')">
                    {{stateData != null ? stateData.work_experience_name : '-'}}
                </b-form-group>
            </div>
            <div class="col-12">
                <h6>{{ $t('contact_information') }}</h6>
            </div>
            <div class="col-12 mt-4">
                <h6>{{ $t('home_address') }}</h6>
            </div>
            <div class="col-6 col-lg-4">
                <b-form-group :label="$t('neighborhood')">
                    {{stateData != null ? stateData.neighbourhood : '-'}}
                </b-form-group>
            </div>
            <div class="col-6 col-lg-4">
                <b-form-group :label="$t('street')">
                    {{stateData != null ? stateData.street : '-'}}
                </b-form-group>
            </div>
            <div class="col-6 col-lg-4">
                <b-form-group :label="$t('street_and_no')">
                    {{stateData != null ? stateData.address : '-'}}
                </b-form-group>
            </div>
            <div class="col-6 col-lg-4">
                <b-form-group :label="$t('city')">
                    {{stateData.provinceInfo != null ? stateData.provinceInfo.name : '-'}}
                </b-form-group>
            </div>
            <div class="col-6 col-lg-4">
                <b-form-group :label="$t('district')">
                    {{stateData.districtInfo != null ? stateData.districtInfo.name : '-'}}
                </b-form-group>
            </div>
            <div class="col-6 col-lg-4">
                <b-form-group :label="$t('home_tel')">
                    {{stateData != null ? stateData.home_phone : '-'}}
                </b-form-group>
            </div>
            <div class="col-12 mt-4">
                <h6>{{ $t('job_address') }}</h6>
            </div>
            <div class="col-6 col-lg-4">
                <b-form-group :label="$t('neighborhood')">
                    {{stateData != null ? stateData.work_neighbourhood : '-'}}
                </b-form-group>
            </div>
            <div class="col-6 col-lg-4">
                <b-form-group :label="$t('street')">
                    {{stateData != null ? stateData.work_street : '-'}}
                </b-form-group>
            </div>
            <div class="col-6 col-lg-4">
                <b-form-group :label="$t('street_and_no')">
                    {{stateData != null ? stateData.work_address : '-'}}
                </b-form-group>
            </div>
            <div class="col-6 col-lg-4">
                <b-form-group :label="$t('city')">
                    {{stateData.workProvinceInfo != null ? stateData.workProvinceInfo.name : '-'}}
                </b-form-group>
            </div>
            <div class="col-6 col-lg-4">
                <b-form-group :label="$t('district')">
                    {{stateData.workDistrictInfo != null ? stateData.workDistrictInfo.name : '-'}}
                </b-form-group>
            </div>
            <div class="col-6 col-lg-4">
                <b-form-group :label="$t('job_tel')">
                    {{stateData != null ? stateData.work_phone : '-'}}
                </b-form-group>
            </div>
            <div class="col-12 mt-4 d-flex justify-content-between">
                <h6>4- {{ $t('education_and_exam_info') }}</h6>
                <b-link @click="goTo(3)">Düzenle</b-link>
            </div>
            <div class="col-12">
                <hr/>
            </div>
            <div class="col-12">
                <h6>{{ $t('education_info') }}</h6>
            </div>
            <div class="col-12">
                <b-table
                    :empty-text="this.$t('there_are_no_records')"
                    bordered
                    hover
                    striped
                    responsive
                    :items="selectedEducations"
                    :fields="selectedEducationsFields"
                    show-empty
                    class="mb-5 table-dropdown no-scrollbar border rounded">
                </b-table>
            </div>
            <div class="col-12">
                <h6>{{ $t('exam_info') }}</h6>
            </div>
            <div class="col-12 col-lg-12">
                <b-form-group :label="$t('bau_language_exam_question')">
                    {{stateData.proficiency_status == 'E' ? $t('yes') : $t('no') }}
                </b-form-group>
            </div>
            <div class="col-12">
                <b-table
                    :empty-text="this.$t('there_are_no_records')"
                    bordered
                    hover
                    striped
                    responsive
                    :items="selectedExams"
                    :fields="selectedExamsFields"
                    show-empty
                    class="mb-5 table-dropdown no-scrollbar border rounded">
                </b-table>
            </div>
            <div class="col-12 mt-4 d-flex justify-content-between">
                <h6>5- {{ $t('documents') }}</h6>
                <b-link @click="goTo(4)">Düzenle</b-link>
            </div>
            <div class="col-12">
                <hr/>
            </div>
            <div class="col-12">
                <div v-for="(file,index) in requiredDocuments" v-bind:key="index">
                    <b-row no-gutters class="file-upload-box mb-1">
                        <b-col sm="12" md="10" lg="10">
                            <div class="filename d-flex" :class="{'active': index == 'other_documents' ? otherDocuments.length > 0 ? true : false : file.status != 'waiting' ? true : false}">
                                <span class="flex-grow-1">{{ file.name }}</span>
                                <span class="flex-grow-1" v-if="file.status != 'waiting' ? true : false">{{ file.file_name }}</span>
                            </div>
                        </b-col>
                        <b-col sm="12" md="2" lg="2">
                            <b-button variant="outline-secondary" block class="download"
                                      :v-b-popover.hover.right="$t('download')"
                                      v-if="index == 'other_documents' ? false : file.status != 'waiting' ? true : false"
                                      @click="downloadFile(index, file.file_name, 0)">
                                {{$t('download').toUpper()}}
                            </b-button>
                            <b-button variant="outline-secondary" block
                                      :v-b-popover.hover.right="$t('upload')"
                                      v-if="index == 'other_documents' ? otherDocuments.length < 1 ? true : false : file.status == 'waiting' ? true : false">
                                {{$t('not_uploaded').toUpper()}}
                            </b-button>
                            <b-button variant="outline-secondary" block class="download"
                                      :v-b-popover.hover.right="$t('upload')"
                                      v-if="index == 'other_documents' ? otherDocuments.length > 0 ? true : false : false">
                                {{$t('status_uploaded').toUpper()}}
                            </b-button>
                        </b-col>
                    </b-row>
                </div>
            </div>
            <div class="col-12" v-if="otherDocuments.length > 0">
                <div class="border pt-3 mb-5 rounded">
                    <div class="col-12" v-for="(file,index) in otherDocuments" v-bind:key="index">
                        <b-row no-gutters class="file-upload-box mb-1">
                            <b-col sm="12" md="10" lg="10">
                                <div class="filename d-flex" :class="{'active': file.status != 'waiting' ? true : false}">
                                    <span class="flex-grow-1">{{ $t('other_documents') }}</span>
                                    <span class="flex-grow-1" v-if="file.status != 'waiting' ? true : false">{{ file.file_name }}</span>
                                </div>
                            </b-col>
                            <b-col sm="12" md="2" lg="2">
                                <b-button variant="outline-secondary" block class="download"
                                          :v-b-popover.hover.right="$t('download')"
                                          v-if="file.status != 'waiting' ? true : false"
                                          @click="downloadFile(index, file.file_name, file.id)">
                                    {{$t('download').toUpper()}}
                                </b-button>
                                <b-button variant="outline-secondary" block
                                          :v-b-popover.hover.right="$t('upload')"
                                          v-if="file.status == 'waiting' ? true : false">
                                    {{$t('not_uploaded').toUpper()}}
                                </b-button>
                            </b-col>
                        </b-row>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import GraduateApplicationService from "@/services/GraduateApplicationService";
import qs from "qs";
import moment from "moment";

export default {
    props: {
        personalInfo: {
            type: Object,
            default: {}
        }
    },
    components: {
        tabIndex: {
            type: Number,
            default: 0
        }
    },
    metaInfo() {
        return {
            title: this.$t('graduate_application_form')
        }
    },
    data() {
        return {
            stateData: {},
            selectedPrograms: [],
            selectedProgramsFields: [
                {
                    key: 'code',
                    label: this.$t('program_code'),
                },
                {
                    key: 'name',
                    label: this.$t('program_name'),
                },
            ],

            selectedEducations: [],
            selectedEducationsFields: [
                {
                    key: 'university_name',
                    label: this.$t('university').toUpper(),
                },
                {
                    key: 'faculty',
                    label: this.$t('faculty'),
                },
                {
                    key: 'department',
                    label: this.$t('department'),
                },
                {
                    key: 'year',
                    label: this.$t('graduation_year').toUpper(),
                },
                {
                    key: 'average',
                    label: this.$t('graduation_average').toUpper(),
                },
            ],

            selectedExams: [],
            selectedExamsFields: [
                {
                    key: 'exam',
                    label: this.$t('exam').toUpper(),
                },
                {
                    key: 'date',
                    label: this.$t('exam_date').toUpper(),
                },
                {
                    key: 'score',
                    label: this.$t('score'),
                },
            ],

            requiredDocuments: [],
            otherDocuments: [],
        }
    },
    created() {
        this.getAllData()
        this.saveBtnInfo()
        this.getProgramPerferences()
        this.getRequiredDocuments()
    },
    mounted() {

    },
    watch: {
        stateData: function (val) {
            this.getExams()
            this.getEducations()
            this.getOtherDocuments()
        }
    },
    methods: {
        getAllData() {
            let config = {
               national_id: this.personalInfo.personalInfo.national_id,
                pin: this.personalInfo.personalInfo.pin
            }

            GraduateApplicationService.show(config).then(response => {
                this.stateData = response.data.data
                this.personalInfo.personalInfo.birthdate = moment(this.personalInfo.personalInfo.birthdate).format('DD-MM-YYYY')
            }).catch(e => {
            })
        },

        saveBtnInfo() {
            this.$swal
                .fire({
                    text: this.$t('preview_btn_info'),
                    icon: 'info',
                    showCancelButton: false,
                    confirmButtonText: this.$t('ok'),
                })
        },

        goTo(tabIndex) {
            this.$emit('goToUpdate', tabIndex)
        },

        getProgramPerferences() {
            let formData = {
                graduate_application_id: this.personalInfo.personalInfo.id,
                pin: this.personalInfo.personalInfo.pin
            }
            GraduateApplicationService.getProgramPerferences(formData).then(response => {
                response.data.data.forEach((itm) => {
                    this.selectedPrograms.push({
                        code: itm.program.code,
                        name: itm.program.name,
                        perferenceId: itm.id
                    })
                })
            })
        },

        getExams() {
            if(this.stateData.exams){
                Object.keys(this.stateData.exams).forEach(index => {
                    this.selectedExams.push({
                        exam: this.stateData.exams[index].name,
                        date: this.stateData.exams[index].date,
                        score: this.stateData.exams[index].score
                    })
                })
            }
        },

        getEducations() {
            this.stateData.educations.forEach(index => {
                this.selectedEducations.push({
                    id: index.id,
                    graduationDegree: index.graduation_degree,
                    university: index.university_id,
                    university_name: index.university.name,
                    faculty: index.faculty,
                    department: index.program,
                    year: index.graduation_year,
                    average: index.gpa,
                    averageSystem: index.graduation_system

                })
            })
        },

        getRequiredDocuments(){
            let formData = {
                graduate_application_id: this.personalInfo.personalInfo.id,
                pin: this.personalInfo.personalInfo.pin
            }
            GraduateApplicationService.requiredDocuments(formData).then(response => {
                this.requiredDocuments = response.data.data
            }).catch(e => {
            })
        },

        getOtherDocuments() {
            if (this.stateData.otherDocuments) {
                this.otherDocuments = this.stateData.otherDocuments
            }
        },

        downloadFile(type, fileName, otherDocumentId){
            let document = {
                type: type
            }
            if(otherDocumentId != 0){
                document.other_document_id = otherDocumentId
                document.type = 'other_documents'
            }

            GraduateApplicationService.downloadDocument(this.personalInfo.personalInfo.id, document).then(response => {
                this._downloadFile(response, fileName)
            }).catch(e => {
            })
        }
    }
};
</script>

